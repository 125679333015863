import { ReceiveQRParams, ReturnedQRData } from './types';

import axiosInstance from 'services/common/instance';

export const postReceiveScanQrV2 = async (params: ReceiveQRParams): Promise<ReturnedQRData> => {
  const { event_type: eventType, ...rest } = params;
  const response = await axiosInstance.post(`shift/event/${eventType}/thank-you`, rest);
  return response.data.data;
};

export const placeholder = '';
