import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import React, { useEffect, useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import thankyouBrandBg from 'assets/images/brand_thanks_bg.png';
import thankyouBrandNotBg from 'assets/images/brand_thanks_not.png';
import mascot from 'assets/images/mascot.png';
import thankyouBg from 'assets/images/thank_you.png';
import Heading from 'components/atoms/Heading';
import Icon from 'components/atoms/Icon';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import NotifyModal from 'components/templates/NotifyModal';
import useWindowSize from 'hooks/useWindowSize';
import { getLocalStorage, setLocalStorage } from 'services/common/storage';
import { postReceiveScanQrV2 } from 'services/events';

const TYPE_SPIN_LUCKY_DRAW = 'TYPE_SPIN_LUCKY_DRAW';

type ErrorCode = {
  title: string;
  code: string;
  message: string;
};

const Home: React.FC = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ErrorCode | undefined>(undefined);
  const [status, setStatus] = useState(true);
  const [manualQRCode, setManualQRCode] = useState<number>();
  const [brandImg, setBrandImg] = useState<string>('');
  const { height } = useWindowSize();
  const [searchParams] = useSearchParams();
  const deviceId = getLocalStorage('deviceId');

  const params = useMemo(() => ({
    event_type: searchParams.get('event_type') || '',
    qr_uuid: searchParams.get('qr_uuid') || '',
    login_id: searchParams.get('login_id') || '',
  }), [searchParams]);

  const {
    data,
    isLoading,
  } = useVisitorData({ ignoreCache: false, }, { immediate: true });

  useEffect(() => {
    if (!!data
      && data.visitorId
      && params.event_type
      && params.qr_uuid
      && params.login_id
      && !isSubmit) {
      const postAction = async () => {
        try {
          (window as any).constellar?.action('receive-qr', { ...params, vid: data.visitorId });
          const res = await postReceiveScanQrV2({
            event_type: params.event_type,
            qr_uuid: params.qr_uuid,
            login_id: params.login_id,
            device_id: data.visitorId,
            device_id_type: deviceId ? 'exist' : 'fresh',
          });
          setManualQRCode(res.manualQrcode);
          setStatus(res.status);
          if (params.event_type === TYPE_SPIN_LUCKY_DRAW) {
            setBrandImg(res.luckyDraw?.brandLogo || '');
          }
          setIsSubmit(true);
        } catch (error) {
          const tempErr = error as ErrorCode[];
          if (tempErr.length > 0) {
            setErrorMessage(tempErr[0]);
          }
          setIsSubmit(true);
        }
      };
      postAction();
    }
  }, [data, params, isSubmit, deviceId]);

  useEffect(() => {
    if (!deviceId && data) {
      setLocalStorage('deviceId', data?.visitorId);
    }
  }, [data, deviceId]);

  const bgImg = useMemo(() => {
    if (!status) return thankyouBrandNotBg;
    return params.event_type === TYPE_SPIN_LUCKY_DRAW ? thankyouBrandBg : thankyouBg;
  }, [status, params]);

  if (!params || Object.keys(params).length < 3) {
    return (
      <div className="p-home_invalid">
        <Heading modifiers={['24x32', '700', 'center']}>
          Đường dẫn không hợp lệ, xin vui lòng thử lại!
        </Heading>
      </div>
    );
  }

  if (isLoading || !isSubmit) {
    return (
      <div className="loading">
        <Icon iconName="loading" size="50" />
      </div>
    );
  }

  if (errorMessage) {
    return (
      <NotifyModal
        isOpen
        title={errorMessage.title}
        desc={errorMessage.message}
      />
    );
  }

  return (
    <div className="p-home" style={{ height: `${height}px` }}>
      <img
        src={bgImg}
        alt="present"
        loading="lazy"
      />
      <div className="p-home_logo">
        <div className="p-home_logo-image">
          <Image imgSrc={brandImg || mascot} size="contain" ratio="1x1" alt="brand-image" />
        </div>
      </div>
      {manualQRCode && isSubmit && status && (
        <div className="p-home_text">
          <Heading type="h2" modifiers={['white', '32x42', '700', 'center', 'ls-5']}>
            {manualQRCode}
          </Heading>
          <div className="p-home_warningText">
            <Text modifiers={['12x14', 'redOrange', '700', 'center', 'shadow']}>
              Mã QR tự động
            </Text>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
