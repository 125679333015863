import './App.scss';
import { CacheLocation, FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import useConstellar from 'hooks/useConstellar';
import useInAppDetect from 'hooks/useInAppDetect';
import Home from 'pages/Home';

const scriptsData = `
  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="//www.blenderanalytics.com/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '12']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();
`;

const ggTagScript = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-X221HXZD51');
`;

const metaScript = `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '295270206731046');
  fbq('track', 'PageView');
`;

const noScript = `
  <img height="1" width="1" style="display:none"
  src="https://www.facebook.com/tr?id=295270206731046&ev=PageView&noscript=1"
  />
`;

const SVGImage: React.FC = () => (
  <div
    className="wrapper"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
      <circle className="tap-1" cx="89.43" cy="64.48" r="19.46" />
      <path className="hand-tap" d="M139.93,102.68,98.81,95.75V65.2A9.25,9.25,0,0,0,89.56,56h0a9.25,9.25,0,0,0-9.25,9.25v57.36L71,111.77c-3.61-3.61-8.44-3.89-13.08,0,0,0-7.24,5.84-3.83,9.25l34,34h42.63a9.25,9.25,0,0,0,9.07-7.43l6.82-34.09A9.28,9.28,0,0,0,139.93,102.68Z" />
    </svg>
  </div>
);

const App: React.FC = () => {
  useConstellar();
  const inApp = useInAppDetect();

  useEffect(() => {
    // Matomo
    const script = document.createElement('script');
    script.innerHTML = scriptsData;
    script.async = true;
    document.body.appendChild(script);
    // Meta
    const metaScriptEl = document.createElement('script');
    const noScriptEl = document.createElement('noscript');
    metaScriptEl.innerHTML = metaScript;
    metaScriptEl.async = true;
    noScriptEl.innerHTML = noScript;
    document.head.appendChild(metaScriptEl);
    document.head.appendChild(noScriptEl);

    const scriptGG = document.createElement('script');
    scriptGG.innerHTML = ggTagScript;
    document.body.appendChild(scriptGG);
  }, []);

  if (inApp) {
    return inApp === 'ios' ? (
      <div className="webView">
        <a
          href={window.location.href}
          style={{ marginBottom: '16px' }}
        >
          Vui lòng nhấn giữ để hoàn tất
        </a>
        <a
          href={window.location.href}
          className="button-location"
        >
          Nhấn Giữ Để Xác Nhận
        </a>
        <a href={window.location.href}>
          <SVGImage />
        </a>
      </div>
    ) : (
      <div className="webView">
        <a
          href={`intent://${window.location.host}${window.location.pathname}${window.location.search}#Intent;scheme=${window.location.protocol.replace(/\W/g, '')};action=android.intent.action.VIEW;end;`}
          style={{ marginBottom: '16px' }}
        >
          Vui lòng nhấn để hoàn tất
        </a>
        <a
          href={`intent://${window.location.host}${window.location.pathname}${window.location.search}#Intent;scheme=${window.location.protocol.replace(/\W/g, '')};action=android.intent.action.VIEW;end;`}
          className="button-location"
        >
          Nhấn Để Xác Nhận
        </a>
        <a href={`intent://${window.location.host}${window.location.pathname}${window.location.search}#Intent;scheme=${window.location.protocol.replace(/\W/g, '')};action=android.intent.action.VIEW;end;`}>
          <SVGImage />
        </a>
      </div>
    );
  }

  return (
    <Routes>
      <Route>
        <Route
          key="router-home"
          path="/"
          element={<Home />}
        />
        <Route path="*" element={<div className="notfound">Not Found!</div>} />
      </Route>
    </Routes>

  );
};

const AppProvider: React.FC = () => (
  <FpjsProvider
    loadOptions={{
      apiKey: 'WGvxQ7BCdZ3OTlpwsw7J',
      region: 'ap',
      scriptUrlPattern: '/loader.js',
    }}
    cacheLocation={CacheLocation.LocalStorage}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </FpjsProvider>
);

export default AppProvider;
